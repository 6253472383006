"use client";
import { useState, useEffect, useRef } from "react";
import RocketIcon from "../graphics/RocketIcon";
import { CloseOutlined } from "@ant-design/icons";
import { cn } from "@/app/_utils/ui";
import StartHuddle from "../huddle/StartHuddle";
import AddHuddleImageDialog from "../huddle/AddHuddleImageDialog";
import JoinHuddleDialog from "../huddle/JoinHuddleDialog";
import { RootState } from "../../reducers/store";
import { useMediaQuery } from "usehooks-ts";
import { useSelector } from "react-redux";
import {
  AddHuddlePicIcon,
  JoinHuddleIcon,
  StartHuddleIcon,
} from "../graphics/huddle";

const HuddleLauncher = ({
  onToggle,
}: {
  onToggle?: (open: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [openAddHuddle, setOpenAddHuddle] = useState(false);
  const [openAddHuddleImage, setOpenAddHuddleImage] = useState(false);
  const [openJoinHuddle, setOpenJoinHuddle] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  const { showHuddleSuccess } = useSelector((state: RootState) => ({
    showHuddleSuccess: state.huddleGroups.showAlertModal,
  }));

  const isSmallScreen = !useMediaQuery("(min-width: 640px)");

  useEffect(() => {
    if (showHuddleSuccess) {
      setOpen(false);
      setOpenAddHuddle(false);
      setOpenJoinHuddle(false);
      setOpenAddHuddleImage(false);
    }
  }, [showHuddleSuccess]);

  useEffect(() => {
    if (!openAddHuddle || !openAddHuddleImage || !openJoinHuddle) {
      setOpen(false);
    }
  }, [openAddHuddle, openAddHuddleImage, openJoinHuddle]);

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open]);

  useEffect(() => {
    // Type the event parameter as MouseEvent
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
        return false;
      }
    }

    // Add event listener when the component is open
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount or when 'open' changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <div
        ref={componentRef}
        className="relative w-full flex-col flex justify-center items-center gap-4 user-select-none"
      >
        <div
          className={cn(
            "absolute -top-[112px] sm:-top-[240px] flex sm:flex-col gap-10 sm:gap-3",
            open ? "pointer-events-auto" : "pointer-events-none",
          )}
        >
          {!isSmallScreen && (
            <div className="hidden sm:block">
              <AddHuddleImageDialog
                open={openAddHuddleImage}
                setOpen={setOpenAddHuddleImage}
              >
                <button
                  id="addHuddleImage"
                  className={cn(
                    "font-medium text-white text-xs pointer-events-auto transition-all ease-in-out",
                    open
                      ? "opacity-100 translate-y-0 duration-300 sm:delay-150"
                      : "opacity-0 translate-y-12 pointer-events-none duration-100",
                  )}
                >
                  <div className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center bg-white rounded-full text-red-600 sm:text-red-600 active:scale-95">
                    <AddHuddlePicIcon />
                  </div>
                  Add Pic
                </button>
              </AddHuddleImageDialog>
            </div>
          )}
          <div>
            <JoinHuddleDialog open={openJoinHuddle} setOpen={setOpenJoinHuddle}>
              <button
                id="joinHuddle"
                className={cn(
                  "font-medium text-white text-xs active:scale-95 transition-all duration-300 relative top-6 sm:top-0",
                  open
                    ? "opacity-100 translate-x-0 translate-y-0 pointer-events-auto scale-100"
                    : "sm:opacity-0 translate-x-[100px] sm:translate-x-0 translate-y-16 scale-50 pointer-events-none duration-100",
                )}
              >
                <div className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center bg-white rounded-full text-red-600 sm:text-red-600">
                  <JoinHuddleIcon />
                </div>
                Join
              </button>
            </JoinHuddleDialog>
          </div>
          <div>
            <StartHuddle open={openAddHuddle} setOpen={setOpenAddHuddle}>
              <button
                id="startHuddle"
                className={cn(
                  "font-medium text-white text-xs active:scale-95 transition-all duration-300",
                  open
                    ? "opacity-100 translate-y-0 pointer-events-auto duration-300"
                    : "sm:opacity-0 scale-50 translate-y-20 pointer-events-none duration-100",
                )}
                onClick={() => setOpenAddHuddle(true)}
              >
                <div className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center bg-white rounded-full text-red-600 sm:text-red-600">
                  <StartHuddleIcon />
                </div>
                Start
              </button>
            </StartHuddle>
          </div>
          {isSmallScreen && (
            <div className="sm:hidden">
              <AddHuddleImageDialog
                open={openAddHuddleImage}
                setOpen={setOpenAddHuddleImage}
              >
                <button
                  className={cn(
                    "font-medium text-white text-xs transition-all ease-in-out relative top-6 sm:top-0",
                    open
                      ? "opacity-100 translate-x-0 translate-y-0 pointer-events-auto duration-300 scale-100 sm:delay-500"
                      : "opacity-0 -translate-x-[100px] translate-y-16 scale-10 pointer-events-none duration-100",
                  )}
                >
                  <div className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center bg-white rounded-full text-red-600 sm:text-red-600 active:scale-95">
                    <AddHuddlePicIcon />
                  </div>
                  Add Pic
                </button>
              </AddHuddleImageDialog>
            </div>
          )}
        </div>

        <button
          id="huddleButton"
          className="text-neutral-700 sm:text-white sm:font-medium text-xs active:scale-95 transition-all duration-100 relative -top-4 sm:top-0 pointer-events-auto"
        >
          <div
            className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center rounded-full bg-red-600"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <CloseOutlined className="scale-[2] sm:scale-150 text-white" />
            ) : (
              <RocketIcon className="relative sm:scale-[.875] top-px right-px" />
            )}
          </div>
          {open ? "Close" : "Huddle"}
        </button>
      </div>
    </>
  );
};

export default HuddleLauncher;
