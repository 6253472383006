import * as Portal from "@radix-ui/react-portal";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showAlertModal } from "../../reducers/huddleSlice";
import { selectWeek } from "@/app/_utils/helper";
import { getTodayDate } from "@/app/_utils/formatter";
import PawIcon from "../graphics/PawIcon";
import { RootState } from "../../reducers/store";
import { Button } from "../buttons/button";
import Lottie from "lottie-react";
import FinishedHuddleMobile from "../animation/FinishedHuddleMobile.json";
import FinishedHuddleDesktop from "../animation/FinishedHuddleDesktop.json";
import { cn } from "@/app/_utils/ui";

const HuddleSuccess: React.FC = () => {
  const { selectedLocation, isVisible, huddleDay, userHuddles } = useSelector(
    (state: RootState) => ({
      selectedLocation: state.locationDashboard.selectedLocation,
      isVisible: state.huddleGroups.showAlertModal,
      huddleDay: state.huddleGroups.huddleDay,
      userHuddles: state.userHuddles,
    }),
  );

  const dispatch = useDispatch();
  const [showInfoBox, setShowInfoBox] = useState(false);

  const currentWeek = selectWeek(new Date());

  const formatedUserHuddles = useMemo(
    () =>
      userHuddles && userHuddles.huddles
        ? Object.entries(userHuddles.huddles).map(([key, value]) => {
            const date = new Date(`${key}Z`);
            const year = date.toLocaleString("default", { year: "numeric" });
            const month = date.toLocaleString("default", { month: "2-digit" });
            const day = date.toLocaleString("default", { day: "2-digit" });
            const formattedDate = year + "-" + month + "-" + day;
            return {
              date: formattedDate,
              articleId: value,
            };
          })
        : [],
    [userHuddles],
  );

  const weeklyUserHuddles = useMemo(
    () =>
      currentWeek.map((weekday) => {
        return {
          ...weekday,
          hasHuddled:
            formatedUserHuddles?.some(
              (userHuddle) => weekday.date == userHuddle.date,
            ) && weekday.date <= getTodayDate(),
        };
      }),
    [currentWeek, formatedUserHuddles],
  );

  useEffect(() => {
    if (isVisible) {
      setShowInfoBox(false);
      const timer = setTimeout(() => {
        setShowInfoBox(true);
      }, 2300);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <Portal.Root
      className={cn(
        `fixed inset-0 z-50 flex items-center justify-center bg-black/40`,
        !isVisible && "hidden",
      )}
    >
      {showInfoBox ? (
        <div
          id="huddleSuccess"
          className="bg-white p-10 rounded-lg w-full max-w-lg m-4 text-center text-dark"
        >
          <h1 className="text-3xl font-bold font-display uppercase mb-8">
            Huddle Saved!
          </h1>
          <div className="grid grid-cols-7 gap-1 justify-items-center mt-12 mb-6">
            {weeklyUserHuddles.map((userHuddle) => (
              <div
                key={userHuddle.date}
                className="flex flex-col items-center justify-center"
              >
                <div
                  className={
                    userHuddle.hasHuddled ? "scale-[2] relative -top-2" : ""
                  }
                >
                  <PawIcon
                    className={
                      userHuddle.hasHuddled ? "animate-scale-in-elastic" : ""
                    }
                    fill={
                      userHuddle.hasHuddled
                        ? "rgb(194 19 31)"
                        : "rgb(229 231 235)"
                    }
                  />
                </div>
                <span className="text-xs font-bold text-neutral-700 mt-1">
                  {userHuddle.weekday.charAt(0)}
                </span>
              </div>
            ))}
          </div>
          <div className="text-sm font-bold tracking-wide font-display uppercase mb-6">
            your weekly huddle streak
          </div>
          <div className="pt-4 border-t flex flex-col">
            <p className="mt-4 text-base">{huddleDay}</p>
            <h2 className="text-2xl font-bold">{selectedLocation?.name}</h2>
            <div className="pt-8">
              <Button onClick={() => dispatch(showAlertModal(false))}>
                Close
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="block md:hidden w-full h-full">
            <Lottie animationData={FinishedHuddleMobile} />
          </div>
          <div className="hidden md:block w-full h-full">
            <Lottie animationData={FinishedHuddleDesktop} />
          </div>
        </div>
      )}
    </Portal.Root>
  );
};

export default HuddleSuccess;
