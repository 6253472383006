import React from "react";

const VEGxERciseIcon = ({ className = "" }) => (
  <span className={className}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2596_6836)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8 19H20.4H20.5V19H21.1V17.4H23.1V14H24.2V10.2H23.1V6.79995L21.1 6.79995V5.19995H20.5H15.9V8.99998L8.3 9V5.19995H4H3.7H3.09985V6.79995L1.1 6.79995V10.2H0V14H1.1V17.4H3V19H3.6L3.6 19H8.2V15.2H15.8V19ZM19.3 16.3V17.3H17.4V13.5H16.8H7.1H6.5V17.3H4.7V16.3V15.7H2.7V12.9H2.1H1.6V11.2L2.1 11.2L2.7 11.2V8.39995H4.7V7.79995V6.79995H6.5V10.6L7.1 10.6H16.9L17.5 10.6V6.79995H19.3V7.79995V8.39995H21.3V11.2L21.9 11.2L22.4 11.2V12.9H21.9H21.3V15.7H19.3V16.3Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2596_6836">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default VEGxERciseIcon;
